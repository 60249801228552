/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

@tailwind base;
@tailwind components;

.link {
  @apply text-blue-600;
  @apply hover:underline;
}

@tailwind utilities;
